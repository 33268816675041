<template>
    <div class="bgm" style="overflow-y:scroll;overflow-x:hidden;height:100%">
        <div class="over_bgc">
            <!-- 顶部栏 -->
            <top-banner theme="white" @backHome="backHome"></top-banner>
            <div class="x_between">
                <!-- 左边 -->
                <div class="left" style="flex:1">
                    <!-- 标题 -->
                    <title-enzh enTitle="GDL SHARE" zhTitle="参数化构件共享计划" fontSizeEn="normal" fontSizeZh="normal"></title-enzh>
                    <div class="idea wrap_x_between">
                        <div style="width:100%">
                            <div class="title" style="white-space:pre-wrap;font-size: 20px; line-height: 35px">{{content}}</div>
                        </div>
                    </div>
                </div>
                <!-- 右边 -->
                <div class="right" style="flex:1">
                    <img src="@/assets/image/home/sixPage/BIMOM.jpg" class="map_size">
                    <!-- 公司信息 -->
                    <div class="company_info">
                        <div class="address">成都公司：四川省成都市高新区天府五街</div>
                        <div class="wrap_x_between">
                            <div v-for="(item,index) in infoList" :key="index" class="info_item y_center">
                                <img :src="item.icon" class="item_icon">
                                <div v-text="item.name" class="item_name"></div>
                            </div>
                            <div class="empty_company_info"></div>
                        </div>
                    </div>
                    <div class="copyright">版权所有 成都悟空和展昭科技有限公司  丨 技术支持 成都悟空和展昭科技有限公司</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import topBanner from "@/components/topBanner.vue"
import titleEnzh from "@/components/titleEnzh.vue"
import {Encrypt} from '../assets/utils/secret.js'
export default {
    components:{
        topBanner,
        titleEnzh
    },
    data(){
        return {
            content:"        为营造一个良好的国内Archicad生态环境，扩大Archicad市场，让更多的AC用户能集中获取和使用符合自己需求的GDL参数化构件，BIMOM构件库平台推出“参数化构件共享计划”，所有有能力编写或者制作GDL的朋友都可以参与，规则如下：" +
                "\n1、 参与人员自行编写或提供GDL构件给平台，并提供简单功能特点介绍，平台将进行功能质量审核（无需提供源码），审核无误后可上线BIMOM构件库平台" +
                "\n2、 参与人员提供的GDL构件分两种方式对外开放：" +
                "\n      a、免费开放：构件免费对外开放，不限制使用；" +
                "\n      b、收费开放：构件以单价形式收费开放，用户购买后方可获得授权，未购买用户限制使用；" +
                "\n      说明：收费与否由参与人员自行决定；收费构件平台仅抽取销售额10%作为平台运营管理费用，其余扣除税费后归提供者所有；收费构件需要在GDL中添加加密代码，平台将提供加密方式；" +
                "\n3、 参与人员所提供的GDL构件必须无版权纠纷，BIMOM构件库平台将显示构件的制作者和版权归属；" +
                "\n4、 欢迎广大AC(GDL)爱好者参与，有兴趣参与请联系：微信：15959358326，邮箱：fanbaoron@163.com，QQ：546570287",

            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            radioList:[
                {id:1,label:"程序BUG"},
                {id:2,label:"功能建议"},
                {id:3,label:"内容意见"},
                {id:4,label:"网络问题"},
                {id:5,label:"账户问题"},
                {id:6,label:"其他"},
            ],
            radio: 1,
            infoList:[
               {icon:require("../assets/image/home/sevenPage/phone.svg"),name:"15959358326"},
               {icon:require("../assets/image/home/sevenPage/wx.svg"),name:"fbr0321"},
               {icon:require("../assets/image/home/sevenPage/qq.svg"),name:"546570287"},
               {icon:require("../assets/image/home/sevenPage/help.svg"),name:"点击咨询在线客服"},
               {icon:require("../assets/image/home/sevenPage/email.svg"),name:"546570287@qq.com"},
           ],
            show: true,
            count: 0,
            counttext:'获取验证码',
            timer: null,
            user_account:"",
            user_name:"",
            user_phone:"",
            user_pwd: "",
            user_pwd1:"",
            code:"",
        }
    },
    methods: {
        handleRemove(file) {
            console.log(file);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        backHome(){
            this.$router.push("/")
        },
        sendmsg(){
            // alert("待上线！")
            let param = {
                user_account: this.user_account,
                user_pwd: Encrypt(this.user_pwd),
                user_phone: this.user_phone,
                user_name: this.user_name,
                code:this.code,
            };
            let config = {
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            console.log(this.currentRow);
            this.axios.post(this.VUE_APP_BASE_API + 'regist', JSON.stringify(param), config).then((res) => {
                if (res.data.resultCode == 0) {
                    this.dialogFormVisible = false;
                    alert("注册成功");
                } else {
                    alert("注册失败:  "+ res.data.resultMsg);
                }
            });
        },
        getCode(){
            let param = {
                user_phone: this.user_phone,
            };
            let config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            console.log(this.currentRow);
            this.axios.post(this.VUE_APP_BASE_API + 'getcode', JSON.stringify(param), config).then((res) => {
                if (res.data.resultCode == 0) {
                    this.dialogFormVisible = false;
                    alert("获取成功");
                    //this.$router.push("/gongxiao")
                    const TIME_COUNT = 60;
                    if (!this.timer) {
                        this.count = TIME_COUNT;
                        this.show = false;
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                                this.counttext=this.count+"秒后重新获取";
                            } else {
                                this.show = true;
                                this.counttext="获取验证码";
                                clearInterval(this.timer);
                                this.timer = null;
                            }
                        }, 1000)
                    }
                } else {
                    alert("获取失败:  "+ res.data.resultData);
                }
            });
        }
    }
}
</script>

<style scoped>
.idea >>> .el-upload-list--picture-card .el-upload-list__item{
    width: 120px;
    height: 120px;
}
.idea >>> .el-upload--picture-card{
    width: 120px;
    height: 120px;
    background-color: transparent;
    border: none;
}
.idea >>> .el-radio__label{
    font-size: 18px;
    color: #fff;
}
</style>
<style lang="scss" scoped>
.empty{
    min-width: 110px;
    height: 0px;
}
.empty_company_info{
    width: 250px;
    height: 0;
}
.bgm{
  width: 100%;
  height: 100vh;
  background: url("../assets/image/feedback/feedback_bgm.jpg") no-repeat;
  background-size: cover;
  overflow: hidden;
  .over_bgc{
    width: 100%;
    min-height: 100vh;
    background: rgba($color: #000000, $alpha: .7);
    padding: 50px 70px !important;
  }
}
.left{
    padding-right:60px; 
    .idea{
        margin: 20px 0;
        padding: 20px;
        border-radius: 6px;
        border: 0px solid rgba(121, 121, 121, 1);
        .title{
            color: #fff;
            text-align: left;
            font-size: 19px;
        }
        .radio_item{
            min-width: 110px;
            margin-top: 20px;
        }
    }
}
.right{
    padding-left: 0px;
    .map_size{
        width: 90%;
        height: 450px;
        margin: 120px 0;
        border-radius: 10px;

    }
    .company_info{
        font-family: '幼圆 Bold', '幼圆', sans-serif;
        font-size: 18px;
        color: #797979;
        margin-top: -80px;
        .address{
            margin-bottom:20px;
            font-weight: bold;
        }
        .info_item{
            font-family: 'Agency FB Negreta', 'Agency FB Normal', 'Agency FB', sans-serif;
            margin-bottom:10px;
            width: 250px;
            .item_icon{
                width:20px;
                margin-right:20px
            }
            .item_name{
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
    .copyright{
        font-family: '幼圆 Bold', '幼圆', sans-serif;
        font-size: 16px;
        color: #797979;
        font-weight: bold;
    }
}


// 输入框
.input_box{
    // margin-top: 10px;
    .input_rect{
        height: 40px;
        padding: 0 20px;
        border-radius: 6px;
        margin-top: 10px;
        background: rgba($color: #fff, $alpha: .5);
        color: #fff;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .lable{
        font-family: '幼圆', sans-serif;
        color: #fff;
        margin-right: 6px;
        font-size: 22px;
    }
    .require_icon{
        font-size: 26px;
        color: #02A7F0;
        vertical-align: middle;
    }
}
textarea{
    background: rgba($color: #fff, $alpha: .5);
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    outline:none;
    padding: 20px;
    margin-top: 10px;
}
.submit{
    width: 100%;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-family: 'Agency FB Normal', 'Agency FB', sans-serif;
    font-size: 18px;
    color: #797979;
    border: 1px solid #797979;
    letter-spacing: 4px;
    border-radius: 20px;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
    margin-top: 20px;
    &:hover{
        color: #fff;
        border: 1px solid #fff;
    }
}
.submit1{
    width: 140px;
    text-align: left;
    height: 45px;
    line-height: 45px;
    font-family: '幼圆', sans-serif;
    /*font-family: 'Agency FB Normal', 'Agency FB', sans-serif;*/
    font-size: 18px;
    color: #797979;
    border: 1px solid #797979;
    letter-spacing: 4px;
    border-radius: 10px;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
    margin-top: 20px;
    &:hover{
        color: #fff;
        border: 1px solid #fff;
    }
}
</style>